body {
  overflow-x: hidden;
}

.full-screen {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.faded {
  opacity: 0;
}

.hidden {
  display: none !important;
}

.layer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

form input[type=image] {
  max-height: 64px;
  max-width: 100%;
}

.form-label {
  margin-bottom: 0.4rem;
}

#donation-button-notice {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
}

#donation-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
#donation-button-wrapper img {
  max-height: 64px;
  max-width: 100%;
}

#form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  transition: opacity 0.4s ease-in-out;
}
#form.map-active {
  background-color: rgba(0, 0, 0, 0.25);
}
#form.map-active #search-form {
  background-color: rgba(255, 255, 255, 0.85);
}
#form.map-active #search-form .close {
  display: block;
}
#form .input-group.autocomplete-results-visible input, #form .input-group.autocomplete-results-visible span {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#form #autocomplete-results {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 2000;
  border: 1px solid #ced4da;
  border-top: none;
}
#form #autocomplete-results .loader {
  display: block;
  margin: 10px;
  font-size: 12px;
}
#form #autocomplete-results .autocomplete-result {
  padding: 5px 12px;
  cursor: pointer;
}
#form #autocomplete-results .autocomplete-result:hover {
  background-color: #f2f2f2;
}
#form #autocomplete-results .autocomplete-result:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#map {
  z-index: 2000;
}

#bg-image {
  background-image: url("./bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  z-index: 1000;
  transition: opacity 0.4s ease-in-out;
}

#search-form {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 0 12px 20px;
  margin: 20px 0;
  position: relative;
}
#search-form .close {
  position: absolute;
  top: var(--bs-gutter-y);
  right: 20px;
  font-size: 24px;
  line-height: 1;
  width: 18px;
  height: 25px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: none;
}

#use-location {
  cursor: pointer;
}

#search-form .button-wrap {
  display: flex;
  justify-content: center;
}

#search-form.loading button[type=submit] {
  display: none;
}

#search-form.loading .loader {
  display: block;
}

#search-form .loader {
  display: none;
  color: #0d6efd;
  font-size: 20px;
  margin: auto;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mapboxgl-popup-content {
  overflow: auto;
  max-height: 80vh;
  border-radius: 7px;
  max-width: 380px;
}

.spot-card {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: none;
  padding: 20px;
  margin-top: 20px;
}
.spot-card form {
  line-height: 0;
}
.spot-card table:last-child {
  margin-bottom: 0;
}
.spot-card table th {
  line-height: 1.3;
}
.spot-card table th, .spot-card table td {
  text-align: center;
  vertical-align: middle;
}
.spot-card table td {
  text-wrap: nowrap;
}
.spot-card table th.date {
  font-size: 115%;
  background-color: #f2f2f2;
}

.spot-card.inner {
  padding: 0;
  margin: 0;
}

.spot-card.my-location .card-title {
  margin-top: 0.7rem;
}
.spot-card .table:last-child {
  margin-bottom: 0;
}
.spot-card .card-body {
  padding: 0 5px;
}
.spot-card .card-body .fa-location-dot {
  padding-right: 1px;
}
.spot-card .card-body .d-flex {
  align-items: center;
}
.spot-card .card-body .fa-diamond-turn-right, .spot-card .card-body .fa-arrow-up-right-from-square {
  padding-left: 10px;
  color: #212529;
}
.spot-card .card-body .fa-diamond-turn-right {
  font-size: 200%;
}
.spot-card .card-body .fa-arrow-up-right-from-square {
  font-size: 170%;
  margin-top: 1px;
}

.spot-card .card-link i {
  text-decoration: none;
  margin-left: 5px;
}

.spot-card .card-link:hover {
  text-decoration: underline;
}

.spot-card .card-link {
  text-decoration: none;
}

.spots-map {
  max-width: 100%;
  margin: 0;
  position: relative;
}
.spots-map > button {
  position: absolute;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}
.spots-map > button.search-this-area {
  bottom: 10px;
}
@media (max-width: 768px) {
  .spots-map > button.search-this-area {
    bottom: 20px;
  }
}
.spots-map > button.spots-count {
  top: 10px;
}
@media (max-width: 768px) {
  .spots-map > button.spots-count {
    right: 10px;
    left: auto;
    transform: none;
  }
}
.spots-map > button.change-filters {
  top: 55px;
}
@media (max-width: 768px) {
  .spots-map > button.change-filters {
    right: 10px;
    left: auto;
    transform: none;
  }
}

.spots-map button[title=Close] {
  top: 0px !important;
  right: 0px !important;
}

.spots-map button[title=Close] span {
  width: 16px !important;
  height: 16px !important;
  margin: 6px !important;
}

.spots-map .wave-icon {
  width: 32px;
  height: 32px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

@media screen and (max-width: 376px) {
  body, .form-control, .form-select, .input-group-text, .btn {
    font-size: 0.9rem;
  }
  .form-control {
    padding: 0.375rem 0.45rem;
    line-height: 1.3;
  }
  #search-form {
    padding: 0 5px 20px;
  }
  .input-group .input-group-text {
    padding: 0.375rem 0.45rem;
  }
  .mapboxgl-popup {
    max-width: 80% !important;
    padding: 10px 5px 10px;
  }
  .spot-card .card-body {
    padding: 0;
  }
}
@media screen and (max-width: 360px) {
  .mapboxgl-popup {
    max-width: 100% !important;
  }
}
.android-promo-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.android-promo-layer .promo-content {
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  position: relative;
}
.android-promo-layer .promo-content p {
  margin-bottom: 15px;
  font-size: 1.2rem;
  display: inline-block;
}
.android-promo-layer .promo-content a img {
  max-width: 200px;
  height: auto;
  margin-bottom: 15px;
}
.android-promo-layer .promo-content .close-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1.2rem;
}
.android-promo-layer .promo-content .close-button:hover {
  color: #000;
}/*# sourceMappingURL=App.css.map */